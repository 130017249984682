import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Sitefooter from './Sitefooter'

import '../assets/stylesheets/application.scss'

const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      {/* <Img fluid={backgroundImage} /> */}
      {children}

      <Sitefooter />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
