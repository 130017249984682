import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Helmet from 'react-helmet'

import { useDarkMode } from './../hooks/useDarkmode'

const Header = ({ data }) => {
  const [theme, toggleTheme] = useDarkMode()

  let bgTransition = '#edf2f7'
  if (typeof window !== 'undefined') {
    if (theme === 'dark') {
      bgTransition = '#313233'
    } else {
      bgTransition = '#edf2f7'
    }
  }

  return (
    <>
      <Helmet>
        <body data-theme={theme} />
      </Helmet>
      <header>
        <div className="header-content">
          <AniLink
            cover
            direction="left"
            duration={0.7}
            bg="#edf2f7"
            to="/"
            className="header__logo"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="41"
              viewBox="0 0 41 41"
            >
              <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#4ED5C6" transform="translate(-160 -16)">
                  <path d="M180.5 16L201 57h-41l4.972-9.944 5.596 2.73c1.67.813 3.326 1.205 4.971 1.176l.352-.013c1.761-.096 3.364-.675 4.808-1.735s2.624-2.53 3.54-4.408l.485-.994c.903-1.852 1.333-3.677 1.288-5.474-.045-1.797-.556-3.417-1.532-4.86-.976-1.443-2.34-2.591-4.093-3.446l-5.55-2.707L180.5 16zm-3.878 15.635l2.41 1.175c1.78.868 2.86 2.097 3.242 3.688.381 1.59.048 3.462-1.002 5.613l-.538 1.103-.177.345c-1.016 1.913-2.24 3.18-3.674 3.803-1.518.658-3.18.547-4.987-.334l-2.247-1.096 6.973-14.297z"></path>
                </g>
              </g>
            </svg>
          </AniLink>
          <ul>
            <li>
              <AniLink
                cover
                direction="left"
                duration={0.7}
                bg={bgTransition}
                to="/"
                activeClassName="active"
              >
                Home
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                direction="left"
                duration={0.7}
                bg={bgTransition}
                to="/about/"
                activeClassName="active"
              >
                About Me
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                direction="left"
                duration={0.7}
                bg={bgTransition}
                to="/portfolio/"
                activeClassName="active"
              >
                Portfolio
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                duration={0.7}
                bg={bgTransition}
                to="/contact/"
                activeClassName="active"
              >
                Contact Me{' '}
              </AniLink>
            </li>
            <li onClick={() => toggleTheme(!theme)} className="themeToggle">
              {theme === 'dark' ? '☀️' : '🌒'}
            </li>
          </ul>
        </div>
      </header>
    </>
  )
}

export default Header
