import React from 'react';
import './../assets/stylesheets/components/footer.scss'

function Name(props) {
    return (
        <section className="footer">
            © 2020 Dirk Mulder
        </section>
    )
}
export default Name